import { useDoc, usePouch } from "use-pouchdb";
import TaskList from "../../components/Lists/TaskList/TaskList";
import { Job } from "../../objects/Job";

function Tasks() {
  const db = usePouch();
  const { doc: jobsDoc, loading: jobsDocLoading } = useDoc<{ jobs: Job[] }>(
    "jobs"
  );

  function toggleHideJob(taskId: number) {
    const jobs = jobsDoc!.jobs.map((job) =>
      job.task.id === taskId ? { ...job, hidden: !job.hidden } : job
    );
    db.put({ ...jobsDoc, jobs: jobs });
  }

  return (
    <div>
      <div className="TasksPage flow">
        {!jobsDocLoading &&
          (jobsDoc !== null ? (
            <TaskList
              jobs={jobsDoc.jobs}
              toggleText="hide?"
              toggle={(job) => job.hidden}
              onChange={toggleHideJob}
            />
          ) : (
            <p>No tasks stored in database.</p>
          ))}
      </div>
    </div>
  );
}

export default Tasks;
