import "../List.css";
import "./LastOrderList.css";
import { PlacedOrder } from "../../../objects/Order";
import SupplyName from "../../UI/SupplyName/SupplyName";
import OrderStatusIcon from "../../UI/OrderStatusIcon/OrderStatusIcon";

interface Props {
  title: string;
  order?: PlacedOrder;
  updated_at?: string;
}

function LastOrderList({ title, order, updated_at }: Props) {
  return (
    <article className="List LastOrderList">
      <header>
        <h2>{title}</h2>
        <span>Amount</span>
      </header>
      <main>
        {order !== undefined ? (
          order.map((item) => (
            <div key={item.supply.id} className="row">
              <div className="order-item">
                <OrderStatusIcon status={item.status} />
                <SupplyName supply={item.supply} nodesc />
                <span className="amount">{item.amount}</span>
              </div>
              <div className="order-pickup">
                <small>
                  Pick-up:{" "}
                  {new Date(item.pickupDate!).toLocaleString([], {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </small>
              </div>
            </div>
          ))
        ) : (
          <span>None</span>
        )}
        <div className="legend">
          <OrderStatusIcon status="open" small />
          <small>Open</small>
          <OrderStatusIcon status="picked-up" small />
          <small>Picked-up</small>
          <OrderStatusIcon status="available" small />
          <small>Available</small>
          <OrderStatusIcon status="out-of-stock" small />
          <small>Out-of-stock</small>
        </div>
        {updated_at && (
          <div className="last_updated">
            <small>Last updated: </small>
            <small>
              {new Date(updated_at).toLocaleString([], {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </small>
          </div>
        )}
      </main>
    </article>
  );
}

export default LastOrderList;
