import MyStockList from "../../components/Lists/MyStockList/MyStockList";
import { Inventory } from "../../objects/Inventory";
import { useDoc, usePouch } from "use-pouchdb";
import { Job } from "../../objects/Job";
import { useEffect, useState } from "react";

const REMIND_AFTER_DAYS = 30;
// TODO: Ask which time makes sense

function MyStock() {
  // Local Database
  const db = usePouch();

  // Load inventory from local database
  const { doc: inventoryDoc, loading: inventoryDocLoading } = useDoc<{
    inventory: Inventory;
    updated_at: Date;
  }>("inventory");

  const { doc: jobsDoc } = useDoc<{ jobs: Job[] }>("jobs");

  const [remindDate, setRemindDate] = useState<Date>();

  useEffect(() => {
    if (inventoryDoc !== null) {
      const rD = new Date(inventoryDoc.updated_at);
      rD.setDate(rD.getDate() + REMIND_AFTER_DAYS);
      setRemindDate(rD);
    }
  }, [inventoryDoc]);

  //update local database
  const updateInventory = (inventory: Inventory) => {
    db.put({
      ...inventoryDoc,
      inventory: inventory,
      updated_at: new Date(),
    });
  };

  return (
    <div className="MyStockPage flow">
      {!inventoryDocLoading &&
        (inventoryDoc !== null ? (
          <>
            <MyStockList
              inventory={inventoryDoc.inventory}
              jobs={jobsDoc?.jobs}
              updateInventory={updateInventory}
            />
            {new Date() > remindDate! && (
              <p>
                You didn't update your inventory since {REMIND_AFTER_DAYS} days,
                please check your inventory.
              </p>
              /* TODO: Check if understandable and maybe make more visible. */
            )}
          </>
        ) : (
          <p>No inventory created yet.</p>
        ))}
    </div>
  );
}

export default MyStock;
