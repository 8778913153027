import { useEffect, useState } from "react";
import { useDoc } from "use-pouchdb";
import { Supply } from "../../../objects/Supply";
import "./SupplyName.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SupplyType } from "../../../objects/SupplyTypes";

interface Props {
  /** The id of the supply to show */
  supply: Supply;
  nodesc?: boolean;
}

/**
 * Displays the icon, name and optional Description of given supply
 */
function SupplyName({ supply, nodesc }: Props) {
  const [supplyType, setSupplyType] = useState<SupplyType | null>(null);

  const { doc: supplyTypeDoc, loading: supplyTypeDocLoading } = useDoc<{
    supplyTypes: SupplyType[];
  }>("supplyTypes");

  useEffect(() => {
    if (!supplyTypeDocLoading && supplyTypeDoc !== null && supply !== null) {
      if (supply.typeId === undefined) {
        console.error("Supply must have a supply-type");
      }
      const possibleSupplyType = supplyTypeDoc.supplyTypes.find(
        (value) => value.id === supply.typeId
      );
      if (possibleSupplyType !== undefined) {
        setSupplyType(possibleSupplyType);
      }
    }
  }, [supplyTypeDoc, supplyTypeDocLoading, supply]);

  return (
    <div
      className="SupplyName"
      style={supply.deleted ? { color: "var(--clr-orange)" } : {}}
    >
      {supplyType ? (
        <>
          {/* @ts-ignore sadly required */}
          <FontAwesomeIcon icon={supplyType.icon} />
        </>
      ) : (
        <FontAwesomeIcon icon={"font-awesome"} />
      )}
      <span>{supply.name}</span>
      {!nodesc && supply.description && (
        <span className="description">{supply.description}</span>
      )}
    </div>
  );
}

export default SupplyName;
