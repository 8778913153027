import "./AdjustAmount.css";

interface Props {
  /** The initial value to display */
  value: number;
  /** Called on pressed add or subtract button */
  onChange?: (value: number) => void;
}

/**
 * Displays an adjustable value
 */
function AdjustAmount({ value, onChange = (value: number) => {} }: Props) {
  function add(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChange(value + 1);
  }

  function sub(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // Don't allow negative numbers
    if (value > 0) {
      onChange(value - 1);
    }
  }

  return (
    <div className="AdjustAmount">
      <button onClick={sub}>-</button>
      <span>{value}</span>
      <button onClick={add}>+</button>
    </div>
  );
}

export default AdjustAmount;
