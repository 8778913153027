import { useContext, useState } from "react";
import OnlineContext from "../../contexts/OnlineContext";
import "./Login.css";

function Login({
  login,
}: {
  login: (username: string, password: string) => void;
}) {
  const isOnline = useContext(OnlineContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [logingIn, setLogingIn] = useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLogingIn(true);
    await login(username, password);
    setLogingIn(false);
  }

  return (
    <div className="auth-form-container">
      <h2>Login</h2>
      {isOnline ? (
        <form className="login-form" onSubmit={(event) => handleSubmit(event)}>
          <label>username</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="username"
            placeholder="username"
            id="username"
          ></input>
          <label>password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="*******"
            id="password"
          ></input>
          <button type="submit">Log In</button>
        </form>
      ) : (
        <p>Please go online, to login.</p>
      )}
      {logingIn && <p>Loging in, please wait! I'm not frozen I swear!</p>}
    </div>
  );
}

export default Login;
