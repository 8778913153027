import AdjustAmount from "../../UI/AdjustAmount/AdjustAmount";
import SupplyExpiry from "../../UI/SupplyExpiry/SupplyExpiry";
import SupplyName from "../../UI/SupplyName/SupplyName";
import "../List.css";
import "./MyStockList.css";
import { Inventory } from "../../../objects/Inventory";
import { calculateSupplyExpiry } from "../../../lib/calculations";
import { Job } from "../../../objects/Job";
import StatusIcon from "../../UI/StatusIcon/StatusIcon";

interface Props {
  /** Item List of current inventory */
  inventory: Inventory;
  /** Job Lists */
  jobs?: Job[];
  /** Function to update inventory whenever the amount is adjusted */
  updateInventory: (inventory: Inventory) => void;
}

/**
 * Display current inventory
 */
function MyStockList({ inventory, jobs, updateInventory }: Props) {
  return (
    <article className="List MyStockList">
      <header>
        <h2>My Stock</h2>
        <span>lasts until</span>
        <span>adjust stock</span>
      </header>
      <main>
        {inventory.length ? (
          inventory.map((item, i) => {
            const expiry = calculateSupplyExpiry(item, jobs || []);
            return (
              <div className="row" key={item.supply.id}>
                <SupplyName supply={item.supply} />
                {expiry ? (
                  <SupplyExpiry expiry={expiry} />
                ) : (
                  <div className="SupplyExpiry">
                    <StatusIcon status="BLUE" />
                    <span>long time</span>
                  </div>
                )}
                <AdjustAmount
                  value={item.amount}
                  onChange={(value) => {
                    // delete entry if delete flag
                    if (value < 1 && item.supply.deleted) {
                      updateInventory(
                        inventory.filter(
                          (current) => current.supply.id !== item.supply.id
                        )
                      );
                    } else {
                      updateInventory(
                        inventory.map((current) =>
                          current.supply === item.supply
                            ? { ...current, amount: value }
                            : current
                        )
                      );
                    }
                  }}
                />
              </div>
            );
          })
        ) : (
          <p>Inventory empty.</p>
        )}
      </main>
    </article>
  );
}

export default MyStockList;
