import { useContext, useState } from "react";
import OnlineContext from "../../contexts/OnlineContext";
import Dev from "../Dev/Dev";
import MyStock from "../MyStock/MyStock";
import Order from "../Order/Order";
import "./Default.css";
import Tasks from "../Tasks/Tasks";
import PackMyBag from "../PackMyBag/PackMyBag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sync, sendOrder } from "../../lib/service-worker-tasks";

enum Pages {
  DEV,
  TASKS,
  STOCK,
  ORDER,
  PACK,
}

function Default({
  logout,
  devLogout,
}: {
  logout: () => void;
  devLogout: () => void;
}) {
  const isOnline = useContext(OnlineContext);

  const [isSyncing, setIsSyncing] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    process.env.NODE_ENV === "development" ? Pages.DEV : Pages.STOCK
  );
  const [onClickCounter, setOnClickCounter] = useState(0);

  function changePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: Pages
  ) {
    event.preventDefault();
    setCurrentPage(page);
  }

  function handleLogout(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    logout();
  }

  async function handleManualSync() {
    setIsSyncing(true);
    await sync();
    await sendOrder();
    setIsSyncing(false);
  }

  let Component: any;
  switch (currentPage) {
    case Pages.DEV:
      Component = () => <Dev devLogout={devLogout} />;
      break;
    case Pages.TASKS:
      Component = Tasks;
      break;
    case Pages.STOCK:
      Component = MyStock;
      break;
    case Pages.PACK:
      Component = PackMyBag;
      break;
    case Pages.ORDER:
      Component = Order;
      break;

    default:
      Component = <p>Page not found</p>;
      break;
  }

  return (
    <div className="Layout-Default">
      <header>
        <h1>
          ComBaCaL{" "}
          <span
            className="subtitle"
            onClick={() => {
              if (onClickCounter >= 4) {
                setCurrentPage(Pages.DEV);
                setOnClickCounter(0);
              } else {
                setOnClickCounter((prev) => prev + 1);
              }
            }}
          >
            Supplies
          </span>
        </h1>
        <div style={{ display: "flex", gap: "0.5em" }}>
          <button
            className="btn-icon"
            disabled={!isOnline || isSyncing}
            style={{ color: isSyncing ? "var(--clr-blue)" : "inherit" }}
            onClick={handleManualSync}
          >
            <FontAwesomeIcon icon={"sync"} />
          </button>
          <span className="icon-container">
            {isOnline ? (
              <FontAwesomeIcon
                /* @ts-ignore sadly required */
                icon={"signal"}
                style={{ color: "var(--clr-turquoise)", fontSize: "1.2em" }}
              />
            ) : (
              <FontAwesomeIcon
                /* @ts-ignore sadly required */
                icon={"signal"}
                style={{ color: "var(--clr-red)", fontSize: "1.2em" }}
              />
            )}
            <small>{isOnline ? "online" : "offline"}</small>
          </span>
        </div>
      </header>

      <aside>
        <ul>
          {process.env.NODE_ENV === "development" && (
            <li>
              <button
                className={currentPage === Pages.DEV ? "active" : ""}
                onClick={(event) => changePage(event, Pages.DEV)}
              >
                Dev Menü
              </button>
            </li>
          )}
          <li>
            <button
              className={currentPage === Pages.STOCK ? "active" : ""}
              onClick={(event) => changePage(event, Pages.STOCK)}
            >
              My Stock
            </button>
          </li>
          <li>
            <button
              className={currentPage === Pages.PACK ? "active" : ""}
              onClick={(event) => changePage(event, Pages.PACK)}
            >
              Pack My Bag
            </button>
          </li>
          <li>
            <button
              className={currentPage === Pages.ORDER ? "active" : ""}
              onClick={(event) => changePage(event, Pages.ORDER)}
            >
              Order
            </button>
          </li>
          <li>
            <button
              className={currentPage === Pages.TASKS ? "active" : ""}
              onClick={(event) => changePage(event, Pages.TASKS)}
            >
              Tasks
            </button>
          </li>
        </ul>
        <button
          className="btn-icon"
          onClick={(event) => handleLogout(event)}
          disabled={!isOnline}
        >
          Logout
        </button>
      </aside>

      <main>
        <Component />
      </main>
    </div>
  );
}

export default Default;
