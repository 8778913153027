import { OrderStatus } from "../../../objects/Order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  status: OrderStatus;
  small?: boolean;
}

function OrderStatusIcon({ status, small }: Props) {
  let icon: IconProp = "question";
  let color = "";

  switch (status) {
    case "available":
      icon = "box";
      color = "var(--clr-orange)";
      break;
    case "open":
      icon = "hourglass";
      break;
    case "out-of-stock":
      icon = "triangle-exclamation";
      color = "var(--clr-red)";
      break;
    case "picked-up":
      icon = "circle-check";
      color = "var(--clr-blue)";
      break;
  }

  return (
    <FontAwesomeIcon
      style={{ color: color }}
      icon={icon}
      size={small ? "sm" : undefined}
    />
  );
}

export default OrderStatusIcon;
