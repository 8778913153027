import { Order, OrderEntry } from "../../../objects/Order";
import { Supply } from "../../../objects/Supply";
import AdjustAmount from "../../UI/AdjustAmount/AdjustAmount";
import SupplyName from "../../UI/SupplyName/SupplyName";
import "../List.css";
import "./NewOrderList.css";

interface Item {
  /** Id of supply to display */
  supply: Supply;
  /** Current stock of supply */
  stock: number;
  /** Amount of supplies required for future tasks */
  required: number;
  /** Amount of supplies automaticly suggested to order */
  suggestion: number;
}

interface Props {
  /** The list of new supplies to order */
  items: Item[];
  newOrder: Order;
  weeks: number;
  onChange: (order: OrderEntry) => void;
}
/**
 * Displays a list of new supplies to order
 */
function NewOrderList({
  items,
  newOrder,
  weeks,
  onChange = (orderEntry) => {},
}: Props) {
  return (
    <article className="List NewOrderList">
      <header>
        <h2>Order</h2>
        <span>currently in stock</span>
        <span>you will need</span>
        <span>order suggestion</span>
      </header>
      <main>
        {items.length > 0 ? (
          items.map((item) => {
            const amount = newOrder.find(
              (orderEntry) => orderEntry.supply.id === item.supply.id
            )?.amount;

            return (
              <div className="row" key={item.supply.id}>
                <SupplyName supply={item.supply} />
                <span className="center">{item.stock}</span>
                <span className="center">{item.required}</span>
                <div className="adjust-column">
                  <AdjustAmount
                    value={amount !== undefined ? amount : item.suggestion}
                    onChange={(value) =>
                      onChange({ supply: item.supply, amount: value })
                    }
                  />
                </div>
              </div>
            );
          })
        ) : (
          <span>No supplies.</span>
        )}
      </main>
    </article>
  );
}

export default NewOrderList;
export type { Item };
