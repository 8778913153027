import "./SupplyExpiry.css";
import StatusIcon, { Status } from "../StatusIcon/StatusIcon";

// TODO: Adjust urgency values as stakeholder wishes
const NUMBER_DAY_RED = 3;
const NUMBER_DAY_ORANGE = 6;

const ONE_DAY = 1000 * 60 * 60 * 24;

interface Props {
  /** The expiry date */
  expiry: Date;
}

/**
 * Displays the expiry date in relative manner
 */
function SupplyExpiry({ expiry }: Props) {
  const diff = Math.round((expiry.getTime() - Date.now()) / ONE_DAY);
  let status: Status = "BLUE";

  if (diff <= NUMBER_DAY_RED) {
    status = "RED";
  } else if (diff <= NUMBER_DAY_ORANGE) {
    status = "ORANGE";
  }

  return (
    <div className="SupplyExpiry">
      <StatusIcon status={status} />
      {diff > 0 && <span>In {diff} day(s)</span>}
      {diff === 0 && <span>Today</span>}
      {diff < 0 && <span>{Math.abs(diff)} day(s) ago</span>}
    </div>
  );
}

export default SupplyExpiry;
