import { InventoryEntry } from "../objects/Inventory";
import { Job, QSupply } from "../objects/Job";
import { Supply } from "../objects/Supply";
import { SupplyType } from "../objects/SupplyTypes";

/**
 * calculates all the supplies the worker needs on a specific date
 */
function calculateSuppliesToPack(
  jobs: Job[],
  supplyTypes: SupplyType[],
  supplies: Supply[]
) {
  const packSupplies: QSupply[] = [];

  for (const job of jobs) {
    for (const jobSupply of job.supplies) {
      const i = packSupplies.findIndex(
        (packSupply) => packSupply.id === jobSupply.id
      );
      // if supply already in array and supply is consumable, add quantity
      if (
        i > -1 &&
        supplyTypes.find(
          (supplyType) =>
            supplyType.id ===
            supplies.find((supply) => supply.id === packSupplies[i].id)?.typeId
        )?.consumable
      ) {
        packSupplies[i].quantity += jobSupply.quantity;
        // if supply not in array, push
      } else if (i < 0) {
        packSupplies.push({ ...jobSupply }); // forces JS to NOT pass by reference
      }
    }
  }

  return packSupplies;
}

function calculateRequiredSupplies(
  supply: Supply,
  weeks: number,
  jobs: Job[],
  supplyTypes: SupplyType[]
) {
  //Only calculate required supply if supply is consumable
  if (
    supplyTypes.find((supplyType) => supplyType.id === supply.id)?.consumable
  ) {
    const endDate = new Date(Date.now() + weeks * 7 * 24 * 60 * 60 * 1000);
    const limitedJobs = jobs.filter(
      (job) => !job.hidden && new Date(job.task.dueOn) < endDate
    );

    let required = 0;
    limitedJobs.forEach((job) =>
      job.supplies.forEach(
        (qSupply) => qSupply.id === supply.id && (required += qSupply.quantity)
      )
    );

    return required;
  } else return 0;
}

/**
 * Calculates supply expiry for given inventory-entry and jobs.
 *
 * @returns `null` if enough supplies for all tasks, else the expiry `Date`
 */
function calculateSupplyExpiry(inventoryEntry: InventoryEntry, jobs: Job[]) {
  const filteredJobs = jobs.filter((job) => !job.hidden);

  let amount = inventoryEntry.amount;

  let lastExpiry = null;
  // if possible we return the date of the task before the final task where supplies deplete
  for (const job of filteredJobs) {
    let quantity =
      job.supplies.find((qSupply) => qSupply.id === inventoryEntry.supply.id)
        ?.quantity || 0;
    amount -= quantity;
    if (amount < 0) {
      /* If lastExpiry is null, then supplies already depleted,
         thus show current job date (should be in the past or today) */
      return new Date(lastExpiry || job.task.dueOn);
    }
    if (lastExpiry !== job.task.dueOn) {
      lastExpiry = job.task.dueOn;
    }
  }

  // We return null, when enough supplies for all jobs or no jobs at all.
  return null;
}
// TODO: Needs testing, with better CHT Data!

export {
  calculateSuppliesToPack,
  calculateRequiredSupplies,
  calculateSupplyExpiry,
};
