import { Job, QSupply } from "../../../objects/Job";
import SupplyName from "../../UI/SupplyName/SupplyName";
import "../List.css";
import "./PackMyBagList.css";
import { calculateSuppliesToPack } from "../../../lib/calculations";
import { Inventory } from "../../../objects/Inventory";
import { SupplyType } from "../../../objects/SupplyTypes";
import { Supply } from "../../../objects/Supply";
import { useEffect, useState } from "react";
import { useDoc } from "use-pouchdb";

interface Props {
  jobs: Job[];
  inventory: Inventory;
  supplyTypes: SupplyType[] | [];
  supplies: Supply[] | [];
}

function PackMyBagList({ jobs, inventory, supplyTypes, supplies }: Props) {
  const { doc: settingsDoc } = useDoc("settings", {
    db: "private",
  });

  const [packSupplies, setPackSupplies] = useState<QSupply[]>([]);

  useEffect(() => {
    const calculations = calculateSuppliesToPack(jobs, supplyTypes, supplies);

    if (settingsDoc?.debug) {
      console.log("Pack calculations:", calculations);
    }

    setPackSupplies(calculations);
  }, [jobs, supplyTypes, supplies, settingsDoc]);

  return (
    <article className="List PackMyBagList">
      <header>
        <h2>What To Pack</h2>
        <span>quantity</span>
      </header>
      <main>
        {jobs.length && packSupplies.length ? (
          packSupplies.map((packSupply) => {
            const supplyInfo = inventory.find(
              (entry) => entry.supply.id === packSupply.id
            )?.supply;
            return (
              <div className="row" key={packSupply.id}>
                {supplyInfo ? (
                  <SupplyName supply={supplyInfo} />
                ) : (
                  <span>Unknown supply</span>
                )}
                <span className="quantity">{packSupply.quantity}</span>
              </div>
            );
          })
        ) : (
          <span>No supplies required.</span>
        )}
      </main>
    </article>
  );
}

export default PackMyBagList;
