import { useState } from "react";
import "./Dev.css";
import { usePouch } from "use-pouchdb";
import { sendOrder, sync } from "../../lib/service-worker-tasks";
import { PrivateSettings } from "../../objects/PrivateSettings";

function Dev({ devLogout }: { devLogout: () => void }) {
  const [response, setResponse] = useState("");
  const db = usePouch<{}>();
  const remoteDb = usePouch<{}>("remote");
  const privateDb = usePouch<{}>("private");

  /** Destroy local pouchDB */
  function destroyDb() {
    db.destroy();
    setResponse("");
  }

  /** Sync pouchDB with CouchDB*/
  function syncDb() {
    db.sync(remoteDb);
  }

  function handleDevLogout(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    devLogout();
  }

  return (
    <div className="Dev flow">
      <h2>Dev Menü</h2>
      <div className="dev-menu">
        <button
          onClick={async () => {
            const settingsDoc = await privateDb.get<PrivateSettings>(
              "settings"
            );
            privateDb.put({ ...settingsDoc, debug: !settingsDoc.debug });
            console.log("Toggled debug mode");
          }}
        >
          Toggle DEBUG output
        </button>
        <button onClick={(event) => handleDevLogout(event)}>
          Logout (NO SYNC)
        </button>
      </div>
      <h3>Service-Worker-Tasks</h3>
      <div className="dev-menu">
        <button onClick={() => sync()}>Sync</button>
        <button onClick={() => sync(true)}>Sync (NO COUCH)</button>
        <button onClick={() => sendOrder()}>send Order</button>
      </div>

      <h3>PouchDB & CouchDB</h3>
      <div className="dev-menu">
        <button onClick={destroyDb}>Delete pouch</button>
        <button
          onClick={async () => {
            console.log(await db.allDocs({ include_docs: true }));
          }}
        >
          Print pouch to console
        </button>
        <button
          onClick={async () => {
            console.log(await privateDb.allDocs({ include_docs: true }));
          }}
        >
          Print PRIVATE pouch to console
        </button>
        <button
          onClick={async () => {
            const settingsDoc = await privateDb.get<PrivateSettings>(
              "settings"
            );
            remoteDb.logIn(
              settingsDoc.credentials.username,
              settingsDoc.credentials.password,
              (error, response) => {
                if (error) {
                  console.error("CouchDB login error", error);
                } else {
                  if (settingsDoc.debug)
                    console.log("CouchDB Login:", response);
                }
              }
            );
          }}
        >
          Re-login couch
        </button>
        <button onClick={syncDb}>Sync pouch and couch</button>
      </div>

      <h3>Requests</h3>
      <pre className="dev-output">{response}</pre>
    </div>
  );
}

export default Dev;
