import PackMyBagList from "../../components/Lists/PackMyBagList/PackMyBagList";
import { Job } from "../../objects/Job";
import { useDoc, usePouch } from "use-pouchdb";
import { Inventory } from "../../objects/Inventory";
import TaskList from "../../components/Lists/TaskList/TaskList";
import { useEffect } from "react";
import { SupplyType } from "../../objects/SupplyTypes";
import { Supply } from "../../objects/Supply";

function PackMyBag() {
  const db = usePouch();

  const { doc: inventoryDoc, loading: inventoryDocLoading } = useDoc<{
    inventory: Inventory;
  }>("inventory");
  const { doc: jobsDoc, loading: jobsDocLoading } = useDoc<{ jobs: Job[] }>(
    "jobs"
  );
  const { doc: toPackJobsDoc } = useDoc<{ toPackJobs: Job[] }>("toPackJobs");
  const { doc: supplyTypesDoc } = useDoc<{ supplyTypes: SupplyType[] }>(
    "supplyTypes"
  );
  const { doc: supplyDoc } = useDoc<{ supplies: Supply[] }>("supplies");

  // remove jobs that got hidden after selection and deleted jobs
  useEffect(() => {
    if (jobsDoc && toPackJobsDoc) {
      db.put({
        ...toPackJobsDoc,
        toPackJobs: toPackJobsDoc.toPackJobs.filter((packJob) => {
          const job = jobsDoc.jobs.find(
            (job) => job.task.id === packJob.task.id
          );
          if (job) {
            // Job exists, filter if hidden
            return !job.hidden;
          }
          // Job does not exist anymore
          return false;
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsDoc]); // We want this effect only to fire on jobsDoc change

  function updateToPackJobs(taskId: number) {
    const job = jobsDoc!.jobs.find((job) => job.task.id === taskId)!;

    if (
      toPackJobsDoc?.toPackJobs.find((sJob) => sJob.task.id === job.task.id)
    ) {
      db.put({
        ...toPackJobsDoc,
        toPackJobs: toPackJobsDoc.toPackJobs.filter(
          (sJob) => sJob.task.id !== job.task.id
        ),
      });
    } else {
      if (toPackJobsDoc) {
        db.put({
          ...toPackJobsDoc,
          toPackJobs: [...toPackJobsDoc.toPackJobs, job],
        });
      } else {
        db.put({ _id: "toPackJobs", toPackJobs: [job] });
      }
    }
  }

  return (
    <div className="PackMyBagPage flow">
      {!jobsDocLoading &&
        !inventoryDocLoading &&
        (inventoryDoc !== null && jobsDoc !== null ? (
          <>
            <PackMyBagList
              jobs={toPackJobsDoc?.toPackJobs || []}
              inventory={inventoryDoc.inventory}
              supplyTypes={supplyTypesDoc ? supplyTypesDoc.supplyTypes : []}
              supplies={supplyDoc ? supplyDoc.supplies : []}
            />
            <TaskList
              jobs={jobsDoc.jobs.filter((job) => !job.hidden)}
              toggleText="Do today"
              toggle={(job) =>
                !!toPackJobsDoc?.toPackJobs.find(
                  (sJob) => sJob.task.id === job.task.id
                )
              }
              onChange={updateToPackJobs}
            />
          </>
        ) : (
          <p>No inventory or tasks in database.</p>
        ))}
    </div>
  );
}

export default PackMyBag;
