import SupplyExpiry from "../../UI/SupplyExpiry/SupplyExpiry";
import "./TaskList.css";
import { Job } from "../../../objects/Job";

interface Props {
  jobs: Job[];
  toggleText: string;
  toggle: (job: Job) => boolean;
  onChange?: (taskId: number) => void;
}

/**
 * Display tasks
 */
function TaskList({ jobs, toggleText, toggle, onChange }: Props) {
  return (
    <article className="List TaskList">
      <header>
        <h2>Select Tasks</h2>
        <span>due date</span>
        <span>{toggleText}</span>
      </header>
      <main>
        {jobs.length ? (
          jobs.map((job) => {
            return (
              <div className="row" key={job.task.id}>
                <div className="TaskName">
                  <span>{job.task.name}</span>
                  <span className="description">{job.task.contact}</span>
                </div>
                <SupplyExpiry expiry={new Date(job.task.dueOn)} />
                <input
                  type="checkbox"
                  onChange={() => {
                    if (onChange) {
                      onChange(job.task.id);
                    }
                  }}
                  checked={toggle(job)}
                />
              </div>
            );
          })
        ) : (
          <p>No tasks available.</p>
        )}
      </main>
    </article>
  );
}

export default TaskList;
