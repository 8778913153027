interface Supply {
  id: number;
  name: string;
  description?: string;
  typeId: number;
  icon?: string;
  deleted?: boolean;
}

function parser(object: any): Supply {
  const restArray = object.type.split("/");
  return {
    id: object.id,
    name: object.name,
    description: object?.description,
    typeId: Number(restArray[restArray.length - 1]),
  };
}

function collectionParser(object: any): Supply[] {
  return object["hydra:member"].map((member: any) => parser(member));
}

function sort(a: Supply, b: Supply) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export type { Supply };
export { parser, collectionParser, sort };
