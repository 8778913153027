import { ReactNode } from "react";
import "./Popup.css";

interface Props {
  title: string;
  children: ReactNode | string;
}

export const Popup = ({ title, children }: Props) => {
  return (
    <div className="popup-container">
      <div className="popup-body flow">
        <h3>{title}</h3>
        {typeof children === "string" ? (
          <p>{children}</p>
        ) : (
          <div className="popup-content flow">{children}</div>
        )}
      </div>
    </div>
  );
};
