import "./Order.css";
import NewOrderList, {
  Item,
} from "../../components/Lists/NewOrderList/NewOrderList";
import { useContext, useEffect, useState } from "react";
import {
  Order as OrderModel,
  OrderEntry,
  PlacedOrder,
} from "../../objects/Order";
import { useDoc, usePouch } from "use-pouchdb";
import { Supply, sort as sortSupplies } from "../../objects/Supply";
import OrderList from "../../components/Lists/OrderList/OrderList";
import OnlineContext from "../../contexts/OnlineContext";
import { sendOrder } from "../../lib/service-worker-tasks";
import AdjustAmount from "../../components/UI/AdjustAmount/AdjustAmount";
import { calculateRequiredSupplies } from "../../lib/calculations";
import { Job } from "../../objects/Job";
import { Inventory } from "../../objects/Inventory";
import { Popup } from "../../components/UI/Popup/Popup";
import SupplyName from "../../components/UI/SupplyName/SupplyName";
import LastOrderList from "../../components/Lists/LastOrderList/LastOrderList";
import { SupplyType } from "../../objects/SupplyTypes";
import QRCode from "react-qr-code";

/**
 * Order Page
 *
 * User can create orders offline, send order and view last sent order
 *
 */
function Order() {
  // ANCHOR PouchDB

  // Local Database
  const db = usePouch();

  // Load all supplies from local database
  const { doc: supplyDoc, loading: supplyLoading } = useDoc<{
    supplies: Supply[];
  }>("supplies");

  // Load all supply types from local database
  const { doc: supplyTypeDoc } = useDoc<{
    supplyTypes: SupplyType[];
  }>("supplyTypes");

  // load pending order from local database
  const { doc: pendingOrder } = useDoc<{ order: OrderModel }>("pendingOrder");

  // load last sent order from local database
  const { doc: lastOrder } = useDoc<{ order: PlacedOrder; updated_at: string }>(
    "lastOrder"
  );

  const { doc: jobsDoc } = useDoc<{ jobs: Job[] }>("jobs");

  const { doc: inventoryDoc } = useDoc<{ inventory: Inventory }>("inventory");

  const { doc: settingsDoc } = useDoc("settings", {
    db: "private",
  });

  // ANCHOR React states
  const [newOrder, setNewOrder] = useState<OrderModel>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [weeks, setWeeks] = useState(4);
  const [isOpenDatePopup, setIsOpenDatePopup] = useState(false);
  const [isOpenQRPopup, setIsOpenQRPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  // TODO: Store amount of weeks as setting

  const [currentQR, setCurrentQR] = useState(0);

  const isOnline = useContext(OnlineContext);

  // ANCHOR React effects

  // Prepares rendering of NewOrderList
  useEffect(() => {
    if (supplyDoc !== null) {
      const newItems = supplyDoc.supplies.map((supply) => {
        const stock =
          inventoryDoc?.inventory.find(
            (inventoryEntry) => inventoryEntry.supply.id === supply.id
          )?.amount || 0;

        const required = calculateRequiredSupplies(
          supply,
          weeks,
          jobsDoc?.jobs || [],
          supplyTypeDoc?.supplyTypes || []
        );

        let suggestion = required - stock;

        if (
          suggestion > 0 &&
          newOrder.find((nSup) => nSup.supply.id === supply.id) === undefined // only adds new suggestions to order
        ) {
          setNewOrder((prev) => [
            ...prev,
            { supply: supply, amount: suggestion },
          ]);
        } else {
          suggestion = 0;
        }

        return {
          supply: supply,
          stock: stock,
          required: required,
          suggestion: suggestion,
        };
      });

      if (settingsDoc?.debug) {
        console.log("Calculation results:", newItems);
      }

      setItems(newItems);
    }
  }, [
    supplyDoc,
    supplyTypeDoc,
    inventoryDoc,
    jobsDoc,
    weeks,
    settingsDoc,
    newOrder,
  ]);

  // ANCHOR onClick and onChange functions

  function addPendingOrder(order: OrderModel) {
    db.put({
      _id: "pendingOrder",
      order: order
        .map((entry) => ({ ...entry, pickupDate: new Date(selectedDate) })) // adds pickupDate to all order entries
        .sort((a, b) => sortSupplies(a.supply, b.supply)),
    });
  }

  function deletePendingOrder() {
    db.remove(pendingOrder!);
  }

  function order() {
    addPendingOrder(newOrder.filter((item) => item.amount !== 0));
    if (isOnline) {
      sendOrder();
      setNewOrder([]);
    }
  }

  /**
   * Updates newOrder state to keep track of user selection
   */
  function changeNewOrder(order: OrderEntry) {
    setNewOrder((prev) => {
      if (prev.findIndex((current) => current.supply === order.supply) === -1) {
        // Add new OrderElement to Order
        return [...prev, { supply: order.supply, amount: order.amount }];
      } else {
        // Update OrderElement in Order
        return prev.map((current) =>
          current.supply === order.supply
            ? { ...current, amount: order.amount }
            : current
        );
      }
      /*
       * newOrder Entries don't get deleted to keep track, if a suggestion is overwritten to 0
       * following this newOrder has to be filtered, while viewing adding to pending
       */
    });
  }

  // ANCHOR Render
  return (
    <div className="OrderPage">
      {!supplyLoading &&
        (supplyDoc !== null ? (
          <>
            {/** ANCHOR - New Order */}
            <div className="flow">
              <NewOrderList
                items={items}
                newOrder={newOrder}
                weeks={weeks}
                onChange={(order) => changeNewOrder(order)}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1em" }}
                >
                  <span>Suggest for</span>
                  <AdjustAmount
                    value={weeks}
                    onChange={(value) => setWeeks(value)}
                  />
                  <span>Weeks</span>
                </div>
                <button
                  onClick={() => setIsOpenDatePopup(true)}
                  disabled={
                    newOrder.filter((item) => item.amount !== 0).length < 1 ||
                    pendingOrder !== null
                  }
                >
                  Order
                </button>
                {/** NOTE - Popup */}
                {isOpenDatePopup ? (
                  <Popup title="Order Supplies">
                    <div
                      className="datePicker"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p>Please select a pickup date:</p>
                      <input
                        type="datetime-local"
                        id="start"
                        value={selectedDate}
                        onChange={(event) =>
                          setSelectedDate(event.target.value)
                        }
                        min={new Date(
                          new Date().setDate(new Date().getDate() + 1)
                        )
                          .toISOString()
                          .slice(0, 16)}
                        max="2030-12-31T23:59"
                      />
                    </div>
                    <div className="gridContainer">
                      {newOrder !== undefined ? (
                        newOrder
                          .filter((item) => item.amount !== 0)
                          .sort((a, b) => sortSupplies(a.supply, b.supply))
                          .map((item) => (
                            <div key={item.supply.id} className="row">
                              <SupplyName supply={item.supply} nodesc />
                              <span>{item.amount}</span>
                            </div>
                          ))
                      ) : (
                        <span>None</span>
                      )}
                    </div>
                    {selectedDate === "" && errorMessage && (
                      <p className="errorMessage">{errorMessage}</p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        gap: "1em",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="confirmOrder"
                        onClick={() => {
                          if (selectedDate !== "") {
                            order();
                            setIsOpenDatePopup(false);
                          } else if (!errorMessage) {
                            setErrorMessage("Please select a pickup date");
                          }
                        }}
                      >
                        Confirm Order
                      </button>
                      <button
                        className="cancelOrder"
                        onClick={() => {
                          setIsOpenDatePopup(false);
                          setErrorMessage(null);
                        }}
                        style={{ backgroundColor: "var(--clr-bg)" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Popup>
                ) : null}
              </div>
            </div>
            {/** ANCHOR - Pending Order & Last Order */}
            <div className="placedOrders">
              <div className="flow">
                <OrderList title="Pending Order" order={pendingOrder?.order} />
                {pendingOrder !== null && !isOnline && (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Please go online to send Order.</span>
                    <button onClick={() => deletePendingOrder()}>
                      Delete Order
                    </button>
                  </div>
                )}
              </div>
              <div className="flow">
                <LastOrderList
                  title="Last Order"
                  order={lastOrder?.order}
                  updated_at={lastOrder?.updated_at}
                />
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button onClick={() => setIsOpenQRPopup(true)}>
                    Scan Orders
                  </button>
                </div>
                {lastOrder?.order && isOpenQRPopup && (
                  <Popup title="Scan Orders">
                    <h4>
                      {lastOrder.order[currentQR].amount} x{" "}
                      {lastOrder.order[currentQR].supply.name}
                    </h4>
                    <div className="current-QR">
                      <button
                        disabled={currentQR <= 0}
                        onClick={() => setCurrentQR((prev) => prev - 1)}
                      >
                        &lt;
                      </button>
                      <div key={lastOrder.order[currentQR].id}>
                        <QRCode
                          value={`${process.env.REACT_APP_BACKEND_URL}/my-orders/received/${lastOrder.order[currentQR].id}`}
                        />
                      </div>
                      <button
                        disabled={currentQR >= lastOrder.order.length - 1}
                        onClick={() => setCurrentQR((prev) => prev + 1)}
                      >
                        &gt;
                      </button>
                    </div>
                    <button onClick={() => setIsOpenQRPopup(false)}>
                      Close
                    </button>
                  </Popup>
                )}
              </div>
            </div>
          </>
        ) : (
          <p>No supplies stored in database.</p>
        ))}
    </div>
  );
}

export default Order;
