// TODO: Error handling

import PouchDb from "pouchdb-browser";
import { PrivateSettings } from "../objects/PrivateSettings";

interface apiParams<ReturnType> {
  requestBody: BodyInit | null;
  withToken: boolean;
  parser: (object: any) => Promise<ReturnType> | ReturnType;
}

async function api<ReturnType>(
  url: string,
  method: string,
  params: Partial<apiParams<ReturnType>>
) {
  const privateDb = new PouchDb("private");
  let settingsDoc;
  try {
    settingsDoc = await privateDb.get<PrivateSettings>("settings");
  } catch (error: any) {
    if (error.status !== 404) {
      throw error; // rethrows all errors expect 404 ones
    }
  }
  const debug =
    settingsDoc !== undefined
      ? settingsDoc.debug
      : process.env.NODE_ENV === "development";

  const defaultParams: apiParams<ReturnType> = {
    requestBody: null,
    withToken: true,
    parser: (object: any) => object as ReturnType,
  };
  const { requestBody, withToken, parser } = { ...defaultParams, ...params };

  let headers: any = {
    "Content-Type": "application/json",
  };
  if (withToken) {
    if (settingsDoc === undefined) {
      throw Error("Can't get token since settings not found!");
    }
    headers = {
      ...headers,
      Authorization: "Bearer " + settingsDoc.token,
    };
  }

  const request = {
    url: process.env.REACT_APP_BACKEND_URL + url,
    data: {
      headers: headers,
      body: requestBody,
      method: method,
    },
  };

  if (debug) {
    console.log("Request", request);
  }

  const response = await fetch(request.url, request.data);

  if (debug) {
    console.log("Response", response);
  }

  if (!response.ok) {
    return Promise.reject(response);
  }

  const data = await response.json();

  if (debug) {
    console.log("Response Data", data);
  }

  const parsedData = await parser(data);

  return parsedData;
}

async function post<ReturnType = any>(
  url: string,
  param: Partial<apiParams<ReturnType>> = {}
) {
  return api<ReturnType>(url, "POST", param);
}

async function get<ReturnType = any>(
  url: string,
  param: Partial<apiParams<ReturnType>> = {}
) {
  return api<ReturnType>(url, "GET", param);
}

export { post, get };
