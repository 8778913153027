interface SupplyType {
  id: number;
  name: string;
  // supplies: Suppy[] <- not useful (yet)
  icon: string;
  consumable: boolean;
}

function parser(object: any): SupplyType {
  return {
    id: object.id,
    name: object.name,
    icon: object.icon,
    consumable: object.consumable,
  };
}

function collectionParser(object: any): SupplyType[] {
  return object["hydra:member"].map((member: any) => parser(member));
}

export type { SupplyType };
export { parser, collectionParser };
