import PouchDb from "pouchdb-browser";
import { Supply } from "./Supply";

interface OrderEntry {
  supply: Supply;
  amount: number;
  pickupDate?: Date;
}

type OrderStatus = "available" | "open" | "out-of-stock" | "picked-up";

interface PlacedOrderEntry extends OrderEntry {
  id: number;
  status: OrderStatus;
}

type Order = OrderEntry[];
type PlacedOrder = PlacedOrderEntry[];

/**
 * Serializes order for backend
 */
function serialize(order: Order) {
  return JSON.stringify(
    order.map((current) => ({
      ...current,
      supply: "/api/supplies/" + current.supply.id, // overwrite for backend
    }))
  );
}

async function parser(object: any): Promise<PlacedOrderEntry> {
  const db = new PouchDb("local");
  return {
    id: object.id,
    supply: (await db.get<{ supplies: Supply[] }>("supplies")).supplies.find(
      (supply) => supply.id === object.supplyId
    ) || { id: -1, name: "Supply not found", typeId: -1 }, //  REVIEW: Good solution?
    amount: object.amount,
    pickupDate: new Date(object.pickupDate),
    status: object.status,
  };
}

async function collectionParser(object: any): Promise<PlacedOrder> {
  return await Promise.all(object.map((order: any) => parser(order)));
}

export type { OrderEntry, Order, PlacedOrderEntry, PlacedOrder, OrderStatus };
export { serialize, parser, collectionParser };
