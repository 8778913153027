import PouchDB from "pouchdb-browser";
import PouchDBAuthentication from "pouchdb-authentication";
import { Inventory } from "../objects/Inventory";
import {
  Supply,
  collectionParser as supplyCollectionParser,
  sort as sortSupplies,
} from "../objects/Supply";
import { collectionParser as supplyTypeCollectionParser } from "../objects/SupplyTypes";
import { SupplyType } from "../objects/SupplyTypes";
import { get, post } from "./api";
import { PrivateSettings } from "../objects/PrivateSettings";
import { Job, collectionParser } from "../objects/Job";
import {
  Order,
  serialize as serializeOrder,
  collectionParser as collectionParserOrder,
  PlacedOrder,
} from "../objects/Order";

/**
 * Loads doc from local database
 */
async function fetchDoc(
  db: PouchDB.Database<{}>,
  doc: "supplies"
): Promise<
  {
    supplies: Supply[];
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
>;
async function fetchDoc(
  db: PouchDB.Database<{}>,
  doc: "supplyTypes"
): Promise<
  {
    supplytypes: SupplyType[];
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
>;
async function fetchDoc(
  db: PouchDB.Database<{}>,
  doc: "inventory"
): Promise<
  {
    inventory: Inventory;
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
>;
async function fetchDoc(
  db: PouchDB.Database<{}>,
  doc: "jobs"
): Promise<
  {
    jobs: Job[];
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
>;
async function fetchDoc(
  db: PouchDB.Database<{}>,
  doc: "lastOrder"
): Promise<
  {
    order: PlacedOrder;
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
>; // ♥️ Typescript
async function fetchDoc(
  db: PouchDB.Database<{}>,
  doc: "supplies" | "supplyTypes" | "inventory" | "jobs" | "lastOrder"
) {
  switch (doc) {
    case "supplies":
      return db.get<{ supplies: Supply[] }>("supplies");
    case "supplyTypes":
      return db.get<{ supplytypes: SupplyType[] }>("supplyTypes");
    case "inventory":
      return db.get<{ inventory: Inventory }>("inventory");
    case "jobs":
      return db.get<{ jobs: Job[] }>("jobs");
    case "lastOrder":
      return db.get<{ order: PlacedOrder }>("lastOrder");
  }
}

/**
 * Sets Doc files up
 */
async function sync_init(db: PouchDB.Database<{}>) {
  let supplyDoc = null;
  let supplyTypeDoc = null;
  let inventoryDoc = null;
  let jobsDoc = null;
  let lastOrderDoc = null;

  try {
    supplyDoc = await fetchDoc(db, "supplies");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error("SW-T-Sync: Error while getting supplyDoc", error);
    }
  }
  try {
    supplyTypeDoc = await fetchDoc(db, "supplyTypes");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error("SW-T-Sync: Error while getting supplyTypeDoc", error);
    }
  }
  try {
    inventoryDoc = await fetchDoc(db, "inventory");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error("SW-T-Sync: Error while getting inventoryDoc", error);
    }
  }
  try {
    jobsDoc = await fetchDoc(db, "jobs");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error("SW-T-Sync: Error while getting jobsDoc", error);
    }
  }
  try {
    lastOrderDoc = await fetchDoc(db, "lastOrder");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error("SW-T-Sync: Error while getting lastOrderDoc", error);
    }
  }

  return { supplyDoc, supplyTypeDoc, inventoryDoc, jobsDoc, lastOrderDoc };
}

/**
 * Fetches Supplies from Backend and stores/updates it to pouch
 */
async function sync_getSupplies(
  db: PouchDB.Database<{}>,
  supplyDoc:
    | ({
        supplies: Supply[];
      } & PouchDB.Core.IdMeta &
        PouchDB.Core.GetMeta)
    | null
) {
  try {
    const supplies = await get<Supply[]>("/supplies", {
      parser: supplyCollectionParser,
    });

    supplies.sort((a, b) => sortSupplies(a, b));

    //if suppliesDoc does not exist yet in pouchDB--> create supplyDoc
    if (supplyDoc === null) {
      await db.put({ _id: "supplies", supplies: supplies });
    }
    //if supplyDoc already exists in pouchDB --> update supplyDoc
    else {
      await db.put({ ...supplyDoc, supplies: supplies });
    }
  } catch (error) {
    console.error(error);
  }
  return fetchDoc(db, "supplies");
}

/**
 * Fetches SupplyTypes from Backend and stores/updates it to pouch
 */
async function sync_getSupplyTypes(
  db: PouchDB.Database<{}>,
  supplyTypeDoc:
    | ({
        supplytypes: SupplyType[];
      } & PouchDB.Core.IdMeta &
        PouchDB.Core.GetMeta)
    | null
) {
  try {
    const supplyTypes = await get<SupplyType[]>("/supply_types", {
      parser: supplyTypeCollectionParser,
    });
    if (supplyTypeDoc === null) {
      await db.put({ _id: "supplyTypes", supplyTypes: supplyTypes });
    } else {
      await db.put({ ...supplyTypeDoc, supplyTypes: supplyTypes });
    }
  } catch (error) {
    console.error(error);
  }
  //return fetchDoc(db, "supplyType");
}

async function sync_getJobs(
  db: PouchDB.Database<{}>,
  jobsDoc:
    | ({
        jobs: Job[];
      } & PouchDB.Core.IdMeta &
        PouchDB.Core.GetMeta)
    | null
) {
  const jobs = await get("/my-tasks", { parser: collectionParser });

  try {
    jobsDoc = await fetchDoc(db, "jobs");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error(error);
    }
  }

  if (jobsDoc === null) {
    await db.put({ _id: "jobs", jobs: jobs });
  } else {
    await db.put({ ...jobsDoc, jobs: jobs });
  }
}

/**
 * Creates Inventory in pouch
 */
async function sync_createInventory(
  db: PouchDB.Database<{}>,
  supplyDoc:
    | {
        supplies: Supply[];
      } & PouchDB.Core.IdMeta &
        PouchDB.Core.GetMeta
) {
  const inventory: Inventory = supplyDoc.supplies.map((current) => ({
    supply: current,
    amount: 0,
  }));

  await db.put({
    _id: "inventory",
    inventory: inventory,
    updated_at: new Date(),
  });
  //return fetchDoc(db, "inventory");
}

/**
 * Updates supply backup in inventory
 */
async function sync_updateSuppliesInInventory(
  db: PouchDB.Database<{}>,
  supplyDoc:
    | {
        supplies: Supply[];
      } & PouchDB.Core.IdMeta &
        PouchDB.Core.GetMeta,
  inventoryDoc: {
    inventory: Inventory;
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
) {
  let inventory = inventoryDoc.inventory.map((invItem) => {
    const newSupply = supplyDoc.supplies.find(
      (supItem) => supItem.id === invItem.supply.id
    );

    return {
      supply: newSupply ? newSupply : { ...invItem.supply, deleted: true },
      amount: invItem.amount,
    };
  });
  // remove deleted supplies on zero stock
  inventory = inventory.filter(
    (current) => !(current.supply.deleted && current.amount === 0)
  );
  await db.put({
    ...inventoryDoc,
    inventory: inventory,
  });
  return fetchDoc(db, "inventory");
}

/**
 * Adds new supplies to inventory
 */
async function sync_addNewSuppliesToInventory(
  db: PouchDB.Database<{}>,
  supplyDoc:
    | {
        supplies: Supply[];
      } & PouchDB.Core.IdMeta &
        PouchDB.Core.GetMeta,
  inventoryDoc: {
    inventory: Inventory;
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
) {
  let newSupplies: Supply[] = [];
  supplyDoc.supplies.forEach((supply) => {
    if (
      inventoryDoc.inventory.find(
        (inventoryEntry) => inventoryEntry.supply.id === supply.id
      ) === undefined
    ) {
      newSupplies.push(supply);
    }
  });

  await db.put({
    ...inventoryDoc,
    inventory: [
      ...inventoryDoc.inventory,
      ...newSupplies.map((supply) => ({ supply: supply, amount: 0 })),
    ].sort((a, b) => sortSupplies(a.supply, b.supply)),
  });
}

/**
 * Updates the last Order with status from server
 */
async function sync_updateLastOrder(
  db: PouchDB.Database<{}>,
  lastOrderDoc: {
    order: PlacedOrder;
  } & PouchDB.Core.IdMeta &
    PouchDB.Core.GetMeta
) {
  const ids = lastOrderDoc.order.map((entry) => entry.id);
  const updatedOrders = await get(`/my-orders/list?ids=${ids.join(",")}`, {
    parser: collectionParserOrder,
  });
  db.put({ ...lastOrderDoc, order: updatedOrders, updated_at: new Date() });
}

/**
 * Syncs pouchdb with backend
 */
async function sync(noCouchdb = false) {
  const privateDb = new PouchDB("private");
  const settingsDoc = await privateDb.get<PrivateSettings>("settings");

  if (settingsDoc.debug) {
    console.log("*** START SW Task Sync ***");
  }

  const db = new PouchDB("local");

  if (settingsDoc.debug) {
    console.log("SW-T-Sync: Loading Docs from Pouch Database.");
  }
  let { supplyDoc, supplyTypeDoc, inventoryDoc, jobsDoc, lastOrderDoc } =
    await sync_init(db);

  if (settingsDoc.debug) {
    console.log(
      "SW-T-Sync: Fetching Supplies, SupplyTypes and Jobs from backend."
    );
  }
  supplyDoc = await sync_getSupplies(db, supplyDoc);
  await sync_getSupplyTypes(db, supplyTypeDoc);
  await sync_getJobs(db, jobsDoc);

  // Inventory logic
  if (inventoryDoc === null) {
    // create
    if (settingsDoc.debug) {
      console.log("SW-T-Sync: Creating _new_ inventory.");
    }
    await sync_createInventory(db, supplyDoc);
  } else {
    // update
    if (settingsDoc.debug) {
      console.log("SW-T-Sync: Updating and deleting entries in Inventory.");
    }
    inventoryDoc = await sync_updateSuppliesInInventory(
      db,
      supplyDoc,
      inventoryDoc
    );
    if (settingsDoc.debug) {
      console.log(
        "SW-T-Sync: Adding new entries to Inventory, if there are some."
      );
    }
    await sync_addNewSuppliesToInventory(db, supplyDoc, inventoryDoc);
  }

  if (lastOrderDoc !== null) {
    if (settingsDoc.debug) {
      console.log("SW-T-Sync: Updating order status.");
    }
    await sync_updateLastOrder(db, lastOrderDoc);
  }

  if (!noCouchdb) {
    if (settingsDoc.debug) {
      console.log("SW-T-Sync: Syncing pouchdb with couchdb.");
    }
    PouchDB.plugin(PouchDBAuthentication);
    const remoteDb = new PouchDB(settingsDoc!.couchdbURL, {
      skip_setup: true,
    });
    remoteDb.logIn(
      settingsDoc.credentials.username,
      settingsDoc.credentials.password,
      (error, response) => {
        if (error) {
          console.error("CouchDB login error", error);
        } else {
          if (settingsDoc.debug) console.log("CouchDB Login:", response);
        }
      }
    );
    db.sync(remoteDb);
  }

  if (settingsDoc.debug) {
    console.log("*** END SW Task Sync ***");
  }
}

/**
 * Sends pending order to backend,
 * deletes entry in local pending order database,
 * creates new entry in local last order database.
 */
async function sendOrder() {
  const db = new PouchDB("local");
  let pendingOrder;
  try {
    pendingOrder = await db.get<{ order: Order }>("pendingOrder");
  } catch (error: any) {
    if (error.status !== 404) {
      console.error("Send order error while getting pending order:", error);
    }
  }

  if (pendingOrder !== undefined) {
    let lastOrder = null;
    try {
      lastOrder = await db.get<{ order: Order }>("lastOrder");
    } catch (error: any) {
      if (error.status !== 404) {
        console.error(error);
      }
    }

    const placedOrder = await post("/orders/multiple", {
      requestBody: serializeOrder(pendingOrder.order),
      parser: collectionParserOrder,
    });

    if (lastOrder === null) {
      db.put({ _id: "lastOrder", order: placedOrder, updated_at: new Date() });
    } else {
      db.put({ ...lastOrder, order: placedOrder, updated_at: new Date() });
    }
    db.remove(pendingOrder);
  }
}

export { sync, sendOrder };
