import "./StatusIcon.css";

type Status = "RED" | "ORANGE" | "BLUE";

interface Props {
  /** The status to display */
  status: Status;
}

/**
 * Display urgency status
 */
function StatusIcon({ status }: Props) {
  return (
    <div className={`StatusIcon ${status}`}>{status === "RED" && "!"}</div>
  );
}

export type { Status };
export default StatusIcon;
