import { useEffect, useState } from "react";
import "./App.css";
import Login from "./pages/Login/Login";
import DefaultLayout from "./pages/_layout/Default";

import PouchDB from "pouchdb-browser";
import PouchDBFind from "pouchdb-find";
import PouchDBAuthentication from "pouchdb-authentication";
import { Provider } from "use-pouchdb";
import { get, post } from "./lib/api";
import OnlineContext from "./contexts/OnlineContext";
import { PrivateSettings } from "./objects/PrivateSettings";
import { sendOrder, sync } from "./lib/service-worker-tasks";

PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBAuthentication);

function App() {
  // () => ... Lazy Init
  const [db, setDB] = useState(() => new PouchDB("local"));
  const [privateDB, setPrivateDB] = useState(() => new PouchDB("private"));
  const [remoteDB, setRemoteDB] = useState<PouchDB.Database<{}> | null>(null);

  // loads and sets remoteDb url from pouchdb settings doc
  useEffect(() => {
    async function ef() {
      let settingsDoc = null;
      try {
        settingsDoc = await privateDB.get<PrivateSettings>("settings");
      } catch (error: any) {
        if (error.status !== 404) {
          console.error("Error while getting settings.", error);
        }
      }
      if (settingsDoc && settingsDoc.couchdbURL !== undefined) {
        setRemoteDB(new PouchDB(settingsDoc.couchdbURL, { skip_setup: true }));
      }
    }
    ef();
  }, [privateDB]);

  // re-render on db.destroy
  useEffect(() => {
    function listener(dbName: string) {
      if (dbName === "local") {
        setDB(new PouchDB("local"));
      }
      if (dbName === "private") {
        setPrivateDB(new PouchDB("private"));
      }
    }

    PouchDB.on("destroyed", listener);
    return () => {
      PouchDB.removeListener("destroyed", listener);
    };
  }, []);

  // Keep track of online state

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleStatusChange() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  // syncs with server, when offline to online (only in production mode)
  useEffect(() => {
    if (process.env.NODE_ENV === "production" && isOnline) {
      sync();
      sendOrder();
    }
  }, [isOnline]);

  async function login(username: string, password: string) {
    try {
      const { token } = await post("/login_check", {
        requestBody: JSON.stringify({
          username: username,
          password: password,
        }),
        withToken: false,
      });

      await privateDB.put({
        _id: "settings",
        credentials: { username: username, password: password }, // FIXME: Remove this workaround, used lib/service-worker-task.ts sync() to re-login in couch
        token: token,
        debug: process.env.NODE_ENV === "development",
      });

      const { couchdbURL } = await get(`/couchdb-url`);

      const settingsDoc = await privateDB.get<PrivateSettings>("settings");
      await privateDB.put({
        ...settingsDoc,
        couchdbURL: couchdbURL,
      });

      let rdb = new PouchDB(couchdbURL, { skip_setup: true });
      rdb.logIn(username, password, (error, response) => {
        if (error) {
          alert("CouchDB login error.");
          console.error(error);
        } else {
          setRemoteDB(rdb);
          if (process.env.NODE_ENV === "development")
            console.log("CouchDB Login:", response);
        }
      });
      rdb.replicate.to(db);
      //Inital service worker sync after login in Production
      if (process.env.NODE_ENV !== "development") {
        sync();
        sendOrder();
      }
    } catch (error: any) {
      alert(
        `Something went wrong.\nMessage: ${error.status} ${error.statusText}`
      );
      console.error(error);
    }
  }

  async function logout() {
    await db.sync(remoteDB!);
    db.destroy();
    remoteDB!.logOut();
    setRemoteDB(null);
    privateDB.destroy();
  }

  function devLogout() {
    db.destroy();
    remoteDB!.logOut();
    setRemoteDB(null);
    privateDB.destroy();
  }

  return (
    <div className="App">
      <OnlineContext.Provider value={isOnline}>
        {remoteDB !== null ? (
          <Provider
            default="local"
            databases={{
              local: db,
              remote: remoteDB,
              private: privateDB,
            }}
          >
            <DefaultLayout logout={logout} devLogout={devLogout} />
          </Provider>
        ) : (
          <Login login={login} />
        )}
      </OnlineContext.Provider>
    </div>
  );
}

export default App;
