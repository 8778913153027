import "../List.css";
import "./OrderList.css";
import { Order } from "../../../objects/Order";
import SupplyName from "../../UI/SupplyName/SupplyName";

interface Props {
  title: string;
  order?: Order;
}

function OrderList({ title, order }: Props) {
  return (
    <article className="List OrderList">
      <header>
        <h2>{title}</h2>
        <span>Amount</span>
      </header>
      <main>
        {order !== undefined ? (
          order.map((item) => (
            <div key={item.supply.id} className="row">
              <SupplyName supply={item.supply} nodesc />
              <span>{item.amount}</span>
            </div>
          ))
        ) : (
          <span>None</span>
        )}
      </main>
    </article>
  );
}

export default OrderList;

